export default class GamesPostModel {
    constructor(item) {
        this._setName(item);
        this._setShortName(item);
        this._setImage(item);
        this._setOrder(item);
    }

    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }

    /**
     * set short name
     * @param shortName
     * @private
     */
    _setShortName({shortName}) {
        this.shortName = shortName;
    }


    /**
     * set image
     * @param image
     * @private
     */
    _setImage({image}) {
        this.photo = image;
    }


    /**
     * set order
     * @param order
     * @private
     */
    _setOrder({order}) {
        this.orderIndex = order;
    }
}
